<template>
  <div class="mb-main__horarios">
    <div
      v-show="loaded.horarios && loaded.horarios !== 100"
      class="mb-main__loading-horarios bg-orange flex items-center justify-between absolute top-0 left-0 w-full text-xs py-4 px-8 font-semibold"
    >
      <span class="uppercase tracking-wider leading-none">Cargando horarios</span>
      <div class="flex items-center">
        <span>{{ parseInt(String(loaded.horarios), 10) }}%</span>
        <Icon
          name="line-md:loading-twotone-loop"
          width="14"
          height="14"
          class="rotation ml-4"
        />
      </div>
    </div>

    <div class="mb-horarios__horaopts">
      <ClientOnly>
        <FechaHora />
      </ClientOnly>

      <div class="mb-horarios__darklight">
        <button
          type="button"
          class="mb-btn"
          :aria-label="`Cambiar a modo ${
            $colorMode.preference === 'dark' ? 'claro' : 'oscuro'
          }`"
          @click="
            $colorMode.preference = $colorMode.preference === 'dark' ? 'light' : 'dark'
          "
        >
          <Icon
            name="line-md:sunny-outline-loop"
            v-if="$colorMode.preference === 'dark'"
            width="16"
            height="16"
            class="stroke-current ml-0"
          />
          <Icon
            name="line-md:sunny-outline-to-moon-alt-loop-transition"
            v-else
            width="16"
            height="16"
            class="stroke-current ml-0"
          />
        </button>
      </div>
    </div>

    <div class="estaciones_servicios items-end flex mb-24">
      <h1 class="text-xl font-semibold leading-none mr-8 mb-8">Estaciones y Horarios</h1>
      <SelectServicio
        :servicios="serviciosArray"
        :servicio-base="servicio"
        @servicio="(srv) => cambiarServicio(srv._id)"
        @star="(srv) => toggleStarServicio(srv._id)"
      />
    </div>

    <div class="mb-estaciones__filtro-opciones">
      <button
        type="button"
        :class="['mb-btn', loadingCoords && 'buscando']"
        @click="getSetGeo"
      >
        {{ loadingCoords ? "Buscando" : "Encontrar" }}
        <Icon name="ri:map-pin-2-line" width="16" height="16" aria-hidden="true" />
      </button>
      <button
        type="button"
        class="mb-btn stroke-current fill-none"
        @click="
          cambiarRuta({
            salida: stateHorario.llegada,
            llegada: stateHorario.salida,
          })
        "
      >
        Invertir
        <Icon
          name="heroicons-solid:switch-vertical"
          width="16"
          height="16"
          aria-hidden="true"
        />
      </button>
    </div>

    <ListaEstaciones
      class="mb-20"
      tipo="Salida"
      :estaciones-base="estaciones"
      :estacionId="String((estacionSalida || {}).id)"
      :estaciones-user="stateUser.estaciones"
      @estacion="(est) => cambiarRuta({ salida: (est || {}).id })"
      @star="(est) => toggleStarEstacion(est)"
    />

    <ListaEstaciones
      class="mb-28"
      tipo="Llegada"
      :estaciones-base="estaciones"
      :estacionId="String((estacionLlegada || {}).id)"
      :estaciones-user="stateUser.estaciones"
      @estacion="(est) => cambiarRuta({ llegada: (est || {}).id })"
      @star="(est) => toggleStarEstacion(est)"
    />

    <ShareHorario
      :salida="estacionSalida?.id"
      :llegada="estacionLlegada?.id"
      :shareData="shareData"
    />

    <div class="mb-horarios__tarifas-container" v-if="tarifas">
      <div
        class="mb-tarifa__saldo"
      >
        <span>General</span>
        <h3>
          <small class="text-sm">$</small>
          {{ tarifas?.general }}
        </h3>
      </div>
      <div
        class="mb-tarifa__saldo"
      >
        <span>Estudiante (67%)</span>
        <h3>
          <small class="text-sm">$</small>
          {{ tarifas?.estudiante || '' }}
        </h3>
      </div>
      <div
        class="mb-tarifa__saldo"
      >
        <span>A. Mayor (50%)</span>
        <h3>
          <small class="text-sm">$</small>
          {{ tarifas?.adultoMayor || '' }}
        </h3>
      </div>
    </div>

    <div class="mb-horarios__mi-saldo" v-if="(tarjetaPrincipal || {}).value">
      <span class="">Mi saldo</span>
      <h4>{{ tarjetaPrincipal?.value.saldo || "$0" }}</h4>

      <button
        type="button"
        class="mb-btn"
        @click="() => fetchSaldo()"
        aria-label="Actualizar saldo tarjeta"
      >
        <Icon
          name="tabler:reload"
          width="14"
          height="14"
          alt="Actualizar saldo tarjetas"
          aria-hidden="true"
          :class="['ml-0', tarjetasStore.actualizando && 'rotation']"
          fill
        />
      </button>
    </div>

    <div class="mb-filtro__estaciones">
      <span>Mostrar</span>
      <button
        type="button"
        :class="['mb-btn', stateUser.limite === 0 && 'activo']"
        @click="toggleLimite(0)"
      >
        todos
      </button>
      <button
        type="button"
        :class="['mb-btn', stateUser.limite === 5 && 'activo']"
        @click="toggleLimite(5)"
      >
        5
      </button>
    </div>

    <ListaHorarios
      v-show="!stateHorario.loading"
      :horarios="stateHorario.horarios"
      :horarios-vuelta="horariosVuelta"
      :estacion-salida="estacionSalida"
      :estacion-llegada="estacionLlegada"
      :limite="stateUser.limite"
      :fecha="fecha"
      :is-loading-horarios="isCargandoHorarios"
    />
  </div>
</template>

<script lang="ts" setup>
import isEmpty from "lodash/isEmpty.js";

import { getEstacionByGeo } from "@/helpers";
import type { Estacion, IEstacion } from "@/interfaces";
import SelectServicio from "@/components/SelectServicio.vue";
import FechaHora from "@/components/FechaHora.vue";
import ListaEstaciones from "@/components/ListaEstaciones.vue";
import ListaHorarios from "@/components/ListaHorarios.vue";
import ShareHorario from "@/components/ShareHorario.vue";

import { useHorarioStore } from "@/store/horarios";
import { useTarjetaStore } from "@/store/tarjetas";
import { useServiciosStore } from "@/store/servicios";
import { useUserStore } from "@/store/userconfig";
import { geoStore } from "@/store/geolocation";
import { horaState } from "@/store/hora";

const router = useRouter();
const route = useRoute();

const servicios = useServiciosStore();
const horarios = useHorarioStore();
const tarjetasStore = useTarjetaStore();
const user = useUserStore();
const geo = geoStore();
const hora = horaState();

let nuevaRuta;
let estacionSalidaRoute: IEstacion | undefined;
let estacionLlegadaRoute: IEstacion | undefined;

const calcularRutas = (servicio = "biotren") => {
  switch (servicio) {
    case "victoria-temuco":
      return { salida: "30", llegada: "38" };
    case "laja-talcahuano":
      return { salida: "7", llegada: "19" };
    case "biotren":
    default:
      return { salida: "23", llegada: "26" };
  }
};

if (!route.params.servicio) {
  router.replace("/biotren");
}

onErrorCaptured((e) => {
  console.log({ error: e });
});

await callOnce(async () => {
  if (isEmpty(servicios.servicios)) {
    await servicios
      .populateServicios(true)
      .catch((e) => console.log(e, "populateServicios"));
  }
  const servicio = servicios.existeServicio(String(route.params.servicio));

  if (isEmpty(servicio) || !servicio) {
    return router.replace("/biotren");
  }
  if (servicio?._id !== servicios.servicioActivo?._id) {
    servicios.cambiarServicio({ servicio: servicio?._id });
  }

  const { salida, llegada } = calcularRutas(String(route.params.servicio));
  
  if (route.params.idsalida) {
    estacionSalidaRoute = servicios.estaciones.find(
      (est) => est.slug === route.params.idsalida
    );

    if (!estacionSalidaRoute) {
      router.replace(`/${servicio?.slug || "biotren"}`)
    };
  }

  if (route.params.idllegada) {
    estacionLlegadaRoute = servicios.estaciones.find(
      (est) => est.slug === route.params.idllegada
    );
    if (!estacionLlegadaRoute)
      router.push(`/${servicio?.slug}/${route.params.idsalida || ""}`);
  }
  
  nuevaRuta = Object.assign(
    {},
    estacionSalidaRoute ? { salida: String(estacionSalidaRoute?.id) } : { salida },
    estacionLlegadaRoute ? { llegada: String(estacionLlegadaRoute?.id) } : { llegada }
  );

  if (estacionSalidaRoute && estacionLlegadaRoute) {
    await horarios
      .getHorario(Object.assign({}, nuevaRuta, { external: true }))
      .catch((e) => console.log("getHorario", e));
  }
});

onMounted(async () => {
  await Promise.all([user.initDB(), servicios.initDB()]);

  if (isEmpty(servicios.servicios)) {
    await servicios.populateServicios(true);
  }

  Promise.all([
    servicios.syncServicios().catch((e) => e),
    tarjetasStore.fetchSaldo(),
  ]).catch((e) => console.log(e));

  const servicioUser = user.servicio;
  if (servicioUser && servicioUser !== route.params.servicio) {
    const servicioUserFull = servicios.servicios.get(servicioUser);

    if (route.params.servicio === servicioUserFull?.slug) {
      servicios.cambiarServicio({ servicio: servicioUser });
    }
  }

  if (!route.params.idsalida) {
    await geo.autoGetGeo();

    let estSalidaGeo: IEstacion | undefined;

    if (geo.coords) {
      estSalidaGeo = getEstacionByGeo(servicios.estaciones)(geo.coords);
    }
    const estacionesUser = user.estaciones;
    const llegada = "16";

    if (estSalidaGeo) {
      horarios.cambiarRuta({ salida: estSalidaGeo?.id, llegada, cambiarRuta: false });
    } else if (
      estacionesUser.length &&
      servicios.estaciones.find((est) => String(est?.id) === String(estacionesUser[0]))
    ) {
      let llegada = String(estacionesUser[1] || 1);
      horarios.cambiarRuta({
        salida: String(estacionesUser[0]),
        llegada,
        cambiarRuta: false,
      });
    } else {
      horarios.getHorario().catch((e) => console.log(e));
    }
  }
});

useHead(() => {
  const hasta = estacionLlegadaRoute ? ` hasta ${estacionLlegadaRoute?.nom}` : "";
  const desde = estacionSalidaRoute ? ` desde ${estacionSalidaRoute?.nom}` : "";

  const title = `Horarios, Saldos y Mapas ${servicios.servicioActivo?.srv}${desde}${hasta}`;
  const description = `Revisa los horarios y valores del ${servicios.servicioActivo?.srv}${desde}${hasta} de manera simple y rapida`;

  let linkHref = 'https://mibiotren.cl/';

  if (servicios.servicioActivo) {
    linkHref += `${servicios.servicioActivo.slug}/`;
  }
  if (estacionSalidaRoute) {
    linkHref += `${estacionSalidaRoute.slug}/`;

    if (estacionLlegadaRoute) {
      linkHref += `${estacionLlegadaRoute.slug}/`;
    }
  }

  return {
    title,
    titleTemplate: "%s | Mibiotren.cl",
    meta: [
      { hid: "og:title", name: "og:title", content: title },
      { hid: "twitter:title", name: "twitter:title", content: title },
      { hid: "description", name: "description", content: description },
      {
        hid: "og:description",
        name: "og:description",
        content: description,
      },
      {
        hid: "twitter:description",
        name: "twitter:description",
        content: description,
      },
    ],
    link: [
      {
        hid: "canonical",
        rel: "canonical",
        href: linkHref,
      },
    ],
  };
});

const fecha = computed(() => hora.date);
const toggleLimite = (value = 0) => user.setPreferencia({ key: "limite", value });
const toggleStarEstacion = (estacion: Estacion) => {
  let estaciones = user.estaciones;

  const existeEstacion = user.estaciones.includes(String(estacion?.id));

  if (existeEstacion) {
    estaciones = estaciones.filter(
      (est: string | number) => String(est) !== String(estacion?.id)
    );
  } else {
    estaciones = estaciones.concat(String(estacion?.id));
  }

  return user.setPreferencia({
    key: "estaciones",
    value: estaciones,
  });
};

const getSetGeo = async () => {
  const coords = await geo.getSetGeo().catch(console.log);
  if (!coords) return;

  const estacion = getEstacionByGeo(servicios.estaciones)(coords.value);

  if (estacion) {
    return horarios.cambiarRuta({ salida: String(estacion?.id) });
  }
};

const loadingCoords = computed(() => false);
const loaded = computed(() => ({
  horarios: horarios.initLoad,
}));
const estaciones = computed(() => servicios.estaciones);
const isCargandoHorarios = computed(() => horarios.isLoadingHorarios);

const stateHorario = computed(() => horarios.$state);
const estacionSalida = computed(() => horarios.estacionSalida);
const estacionLlegada = computed(() => horarios.estacionLlegada);
const cambiarRuta = horarios.cambiarRuta;

const shareData = computed(() => {
  const hasta = estacionLlegadaRoute ? ` hasta ${estacionLlegadaRoute?.nom}` : "";
  const desde = estacionSalidaRoute ? ` desde ${estacionSalidaRoute?.nom}` : "";
  const title = `Horarios, Saldos y Mapas ${servicios.servicioActivo?.srv}${desde}${hasta} | Mibiotren.cl`;
  const description = `Revisa los horarios y valores del ${servicios.servicioActivo?.srv}${desde}${hasta} de manera simple y rapida`;

  return {
    title,
    text: description,
    url: `https://mibiotren.cl/${servicios.servicioActivo?.slug}/${horarios.estacionSalida?.slug}/${horarios.estacionLlegada?.slug}`,
  };
});
const tarjetaPrincipal = computed(() => tarjetasStore.principal);
const fetchSaldo = tarjetasStore.fetchSaldo;

const stateUser = computed(() => user.$state);
const servicio = computed(() => servicios.servicio);
const serviciosArray = computed(() => servicios.serviciosArray);
const horariosVuelta = computed(() => horarios.horariosVuelta);

const tarifas = computed(() => {
  const tarifaBase = parseInt(stateHorario.value.horarios[0]?.valor, 10);
  if (tarifaBase && servicio.value == '1') {
    const tarifaEstudiante = Math.floor(tarifaBase * (1 - 0.67));
    const tarifaAdultoMayor = Math.floor(tarifaBase * 0.5);
  
    return {
      general: tarifaBase,
      estudiante: tarifaEstudiante,
      adultoMayor: tarifaAdultoMayor,
    };
  } else {
    return null;
  }
})

const cambiarServicio = async (servicio: string) => {
  if (servicio !== servicios.servicio) {
    
    servicios.cambiarServicio({ servicio });
    const existeServicio = servicios.servicios.get(servicio);
    if (existeServicio) {
      user.setPreferencia({ key: "servicio", value: servicio });
    }

    if (!isEmpty(servicios.servicioActivo)) {
      router.push(`/${servicios.servicioActivo.slug}`);
    }
  }
};
const toggleStarServicio = (srv: string) => { };
</script>

<style lang="scss">
.mb-main__horarios {
  min-height: 100vh;
  @apply px-16 pt-32 pb-56 mb-40;
}
.mb-horarios__horaopts {
  @apply flex justify-between mb-36;
}

.mb-estaciones__filtro-opciones {
  @apply flex justify-end mb-16;

  button {
    @apply ml-4;
  }
}
.mb-horarios__tarifas-container {
  @apply grid grid-cols-3 mb-12;
}
.mb-tarifa__saldo {
  @apply text-center leading-none border-r border-grey-light;

  &:last-of-type {
    @apply border-r-0;
  }

  span {
    @apply text-xs uppercase mb-4 block tracking-wider;
  }
  h3 {
    @apply text-2xl flex items-center justify-center;
    small {
      @apply mr-4;
    }
  }
  svg {
    @apply text-gray-500;
  }
}
.dark-mode .mb-tarifa__saldo {
  @apply border-gray-700;
}
.mb-horarios__mi-saldo {
  background: hsla(35, 84%, 50%, 0.2);
  border-color: hsla(35, 84%, 50%, 0.3);
  color: hsl(35, 100%, 23%);

  @apply p-8 px-12 rounded-sm border mb-24 flex items-center;
  span {
    @apply uppercase text-xs tracking-wider;
  }
  h4 {
    @apply font-semibold ml-auto;
  }

  button {
    border-color: hsla(35, 84%, 50%, 0.3);
    @apply ml-8 px-4;

    svg {
      @apply ml-0;
    }
  }
}
.dark-mode {
  .mb-horarios__mi-saldo {
    color: hsl(35, 100%, 90%);
  }
}

.mb-filtro__estaciones {
  @apply flex items-center justify-end mb-16;

  span {
    @apply text-xs uppercase tracking-wider text-grey-darker mr-4;
  }
  button {
    @apply ml-4;
  }
}
.dark-mode .mb-filtro__estaciones {
  span {
    @apply text-gray-300;
  }
}
</style>
